@charset "utf-8";

/*
------------------------------------------------------------
Project Name : Infin 8;
------------------------------------------------------------
*/
@font-face {
    font-family: 'averta_cybold';
    src: url('../fonts/averta_cyrillic_bold-webfont.woff2') format('woff2'),
         url('../fonts/averta_cyrillic_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'averta_cyregular';
    src: url('../fonts/averta_cyrillic_regular-webfont.woff2') format('woff2'),
         url('../fonts/averta_cyrillic_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'averta_cysemibold';
    src: url('../fonts/averta_cyrillic_semibold-webfont.woff2') format('woff2'),
         url('../fonts/averta_cyrillic_semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


/* Reset 
--------------------------------------------------------------------------------------------------------*/

/* 
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }
body { line-height: 1; }
ol, ul { list-style: none; }
blockquote, q { quotes: none; }
blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }
table { border-collapse: collapse; border-spacing: 0; }

/* General 
--------------------------------------------------------------------------------------------------------*/
* { outline: none !important; box-sizing: border-box;}
.window-resizing *{transition: none!important; }
a{transition: all 0.3s;}
html, body { min-width: 320px; min-height: 100vh; }
body { font-size: 16px; line-height: 22px; font-family: 'averta_cyregular', sans-serif; -ms-overflow-style: scrollbar; -webkit-font-smoothing: subpixel-antialiased;}
body:not(.white-bg){background: #E5E5E5;}
.wrapper { min-height: 100vh; }
a { color: #0F1A30; text-decoration: none; }
a:hover { color: #252ED1; text-decoration: none; }
h1, h2, h3, h4, h5, h6 { line-height: 1.2em; padding: 0 0 20px; }
h1 span, h2 span, h3 span, h4 span, h5 span, h6 span { display: block; }
h1 {font-size: 38px;}
h2 {font-size: 28px;}
h3 {font-size: 26px; }
h4 {font-size: 24px; }
h5 {font-size: 20px;}
h6 {font-size: 17px;}
p { padding: 0 0 20px; }
input[type=text], input[type=email], input[type=password], textarea { -webkit-appearance: none; appearance: none; }
::-webkit-input-placeholder { color: #98A2A8; }
::-moz-placeholder { color: #98A2A8; }
:-ms-input-placeholder { color: #98A2A8; }
:-moz-placeholder { color: #98A2A8; }
::-moz-focus-inner { border: 0; }
select:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }
option:not(:checked) { color: black; }
.semi-bold{font-family: "averta_cysemibold", sans-serif;}
.bold{font-family: "averta_cybold", sans-serif;}
.blue-text{color: #252ED1;}
.min-w-200{min-width: 200px;}
.fs-14{ font-size: 14px; line-height: 18px; }

/* Header
--------------------------------------------------------------------------------------------------------*/
.header{background: #252ED1; padding: 20px 15px;}
.header .header-logo{height: 40px; width: auto; display: block; margin:auto;}
.page-header{padding: 35px; position: relative;}
.page-header:before{background: #252ED1; content: ""; position: absolute; left: 0; top: 0; width: 100%; height: 370px; z-index: -1;}
.page-header .logo-link-outer{width: 275px; padding-right: 35px; text-align: center;}
.page-header .logo-link{display: inline-block;}
.page-header .logo-link img{display: block; height: 40px;}
.page-header .notification-btn,.page-header .refresh-btn{padding: 0!important; height: 40px; width: 40px; position: relative;}
.page-header .notification-btn:hover{background: #3c45ef!important}
.page-header .notification-btn i{position: absolute; left: 55%; top: 5%; width:10px; height: 10px; background: #FF6363; border-radius: 10px;}
.page-header .notification-btn img{display: block; margin: auto;}
.page-header .btn.btn-link{padding: 0 7px; border-radius: 8px;}

/* Login and Signup
--------------------------------------------------------------------------------------------------------*/
.form-block{max-width: 480px; margin: 0 auto; padding:0 15px;}
.form-block .form-block-top{text-align: center; padding: 20px 0; color:#181C1F; border-bottom: 1px solid #D1D2E0;}
.form-block .form-block-top .main-img{display: block; margin: 0 auto; max-width: 100%; height: auto; width: 200px;}
.form-block .form-block-top .title-text{max-width: 350px; margin: auto; padding: 0;} 
.form-block .form-block-body{padding: 20px 0; max-width: 325px; margin: auto;}
.form-block .form-block-body .form-title{color:#252ED1;}
.form-block .form-block-body .forgot-psw{font-size: 14px; text-decoration: underline; line-height: 18px;}
.form-block .form-block-body .bottom-pera{font-size: 14px; line-height: 18px;}
.form-block .form-block-body .bottom-pera a{color:#007AFF; text-decoration: underline;}
.form-block .form-block-body .bottom-pera a:hover{color:#252ED1;}

/* Form
--------------------------------------------------------------------------------------------------------*/
.btn{padding: 11px 15px; box-shadow: none!important; border-color: transparent!important; font-family: "averta_cysemibold", sans-serif; font-size: 18px; line-height: 22px; border-radius: 8px;}
.btn-lg{padding: 15px;}
.btn.btn-link{font-size: 16px; line-height: 16px; padding: 0; border-radius: 0; text-decoration: none; color: #007AFF!important;}
.btn.btn-link:hover{color:#252ED1!important;}
.btn .btn-icon{float: left; margin: -1px 8px -1px 0; height: 24px; width: 24px;}
.btn.icon-btn{border-radius: 20px; padding: 6px; line-height: 0; font-size: 0;}
.btn.icon-btn .btn-icon{float: none; display: block; margin: 0 auto;}
.btn-primary{background-color: #252ED1!important;}
.btn-primary:hover{background-color: #3c45ef!important;}
.btn-secondary{background-color: #F2F3F5!important; color: #181C1F!important;}
.btn-secondary:hover{background-color: #d4d4d4!important;}
.btn-light{background-color: #F6F8FC!important;}
.btn-light:hover{background-color: #e8e8e8!important;}
.btn-danger{background-color: #FF6363!important;}
.btn-danger:hover{background-color: #ef5050!important;}


.form-control{border-radius: 0; padding:17px 0; height: 58px; border:0; border-bottom: 1px solid #EFF2F7; box-shadow: none!important; color:#0F1A30; font-size: 17px; line-height: 17px; font-family: 'averta_cyregular', sans-serif;}
.form-control:focus{border-bottom-color:#252ED1; color:#0F1A30;}
.form-control.is-invalid{padding-right: 0; background: none;}
.password-block{position: relative;}
.password-block .form-control{padding-right: 30px;}
.password-block .password-icon{width: 20px; height: 20px; position: absolute; background: url(../images/eye-icon.svg) no-repeat center center; right: 0; cursor: pointer; top: 19px;}
.password-block.show .password-icon{background-image: url(../images/eye-icon-2.svg);}
.password-block .password-icon:hover{opacity: 0.7;}
.form-control.datepicker-input{padding-right: 30px; background: url(../images/datepicker-icon.svg) right center no-repeat;}
.form-control.country-code-input{padding-left: 30px; background: url(../images/country-flag.svg) left center no-repeat; width: 70px;}
input::-ms-reveal, input::-ms-clear {display: none;}

.custom-select{border-radius: 0; padding:15px 30px 17px 0; height: 58px; border:0; border-bottom: 1px solid #EFF2F7; box-shadow: none!important; color:#0F1A30; font-size: 17px; font-family: 'averta_cyregular', sans-serif; background: url(../images/dropdown-arrow-dark.svg) right 5px center no-repeat;}
.DayPicker-Caption .custom-select {padding:15px 25px 17px 0;}
.custom-control:not(.custom-switch){padding-left: 0;}
.custom-control:not(.custom-switch) .custom-control-label{font-size: 18px; line-height: 26px; font-family: 'averta_cyregular', sans-serif; padding-left: 38px;}
.custom-control:not(.custom-switch) .custom-control-label::before{width: 26px; height: 26px; border: 1px solid #98A2A8; left: 0; top: 0; box-shadow: none!important; background: none!important;}
.custom-control:not(.custom-switch) .custom-control-label::after{width: 26px; height: 26px; left: 0; top: 0; background: url("../images/checked-icon.svg") no-repeat center center!important;}
.custom-control:not(.custom-switch) .custom-control-input:checked~.custom-control-label::before{border-color: #252ED1; background: #252ED1!important;}

.custom-control.custom-switch{padding: 0;}
.custom-control.custom-switch .custom-control-label{font-size: 18px; line-height: 26px; font-family: 'averta_cyregular', sans-serif; padding-right: 56px;}
.custom-control.custom-switch .custom-control-label::before{width: 46px; height: 26px; border-radius: 26px; border: 1px solid #EFF2F7!important; left:auto; right: 0; top: 0; box-shadow: none!important; background: none!important; transition: all 0.2s;}
.custom-control.custom-switch .custom-control-label::after{width: 26px; height: 26px; border-radius: 16px; left: auto; right: 0; top: 0; box-shadow: -3px 3px 11px rgba(0, 0, 0, 0.25); background: #fff; transform: translateX(-20px); transition: all 0.2s;}
.custom-control.custom-switch .custom-control-input:checked~.custom-control-label::before{background: #40C35B!important;}
.custom-control.custom-switch .custom-control-input:checked~.custom-control-label::after{transform: scale(1);}

.radio-group .custom-control + .custom-control{margin-top: 12px;}

.custom-check .form-check{ position: relative; padding-left: 38px; }
.custom-check .form-check .form-check-input{ opacity: 0; width: 0; height: 0; }
.custom-check .form-check .form-check-label{font-size: 17px; line-height: 25px; font-family: 'averta_cyregular', sans-serif;cursor: pointer; color: #0F1A30; }
.custom-check .form-check .form-check-label::before, .custom-check .form-check .form-check-label::after{ content: ""; position: absolute; top: 0; left: 0; width: 26px; height: 26px; border-radius: 50%; transition: all 150ms; }
.custom-check .form-check .form-check-label::before{ border: 1px solid #98A2A8!important; box-shadow: none!important; background: none!important; }
.custom-check .form-check .form-check-label::after{ background: #242dc3 url('../images/checked-icon.svg') no-repeat center; background-size: 16px 16px; transform: scale(0.2); opacity: 0; visibility: hidden; }
.custom-check .form-check .form-check-input:checked~.form-check-label::before{ background: #40C35B!important; opacity: 0; visibility: hidden; }
.custom-check .form-check .form-check-input:checked~.form-check-label::after{ transform: scale(1); opacity: 1; visibility: visible; }
.custom-check-radius .form-check .form-check-label::before, .custom-check-radius .form-check .form-check-label::after{border-radius: 0px !important;}
.alert-dismissible .close{padding: 11px;}
.alert-dismissible .close span{display: block; font-family: 'averta_cyregular', sans-serif;  position: relative; top: -2px;}
.alert-primary{color: #252ED1; background-color: rgba(37, 46, 209, 0.05); border-color:rgba(37, 46, 209, 0.2);}
.alert-success{color: #48b356; background-color: rgba(72,179,86, 0.05); border-color:rgba(72,179,86, 0.2);}
.alert-danger{color: #FF6363; background-color: rgba(255,99,99, 0.05); border-color:rgba(255,99,99, 0.2);}

.primary-alert-message > div > div{color: #fff; background-color: #252ED1!important; border-color:#252ED1!important;}
.primary-alert-message > div > div > svg{stroke:#fff!important; margin-right: 10px!important;}
.success-alert-message > div > div{color: #fff; background-color: #48b356!important; border-color:#48b356!important;}
.success-alert-message > div > div > svg{stroke:#fff!important; margin-right: 10px!important;}
.danger-alert-message > div > div{color: #fff; background-color: #FF6363!important; border-color:#FF6363!important;}
.danger-alert-message > div > div > svg{stroke:#fff!important; margin-right: 10px!important;}

.alert-box{position: relative; padding: .75rem 3.25rem .75rem 2.25rem; border: 1px solid transparent; border-radius: .25rem;}
.alert-box i{ position: absolute; top: 0; left: 0; padding:13px 9px; color: inherit; background: none; border:0;}
.alert-box i img{display: block; width: 20px; height: 20px;}
.alert-box button{ position: absolute; top: 0; right: 0; padding: 11px; color: inherit; background: none; border:0;}
.alert-box button span{display: block; font-family: 'averta_cyregular', sans-serif;  position: relative; top: -2px;}
.alert-box.alert-primary{color: #fff; background-color: rgba(37, 46, 209, 1); border-color:rgba(37, 46, 209, 1);}
.alert-box.alert-success{color: #fff; background-color: rgba(72,179,86, 1); border-color:rgba(72,179,86, 1);}
.alert-box.alert-danger{color: #fff; background-color: rgba(255,99,99, 1); border-color:rgba(255,99,99, 1);}

.custom-dropdown{border-bottom: 1px solid #EFF2F7; padding: 10px 0;}
.custom-dropdown .custom-dropdown__control{display: flex;}
.custom-dropdown .custom-dropdown__control .custom-dropdown__value-container{padding: 10px; color: #0F1A30; font-size: 17px; line-height: 17px; font-family: 'averta_cyregular', sans-serif;}
.custom-dropdown .custom-dropdown__control .custom-dropdown__indicator-separator{display: none;}
.custom-dropdown-height .custom-dropdown__control{height:72px;}

.form-control.daterangepicker-input{padding:0; background: url(../images/datepicker-icon.svg) right center no-repeat;}
.form-control.daterangepicker-input.focused{border-bottom-color: #252ED1;}
.form-control.daterangepicker-input .InputFromTo{display: flex; align-items: center; position: relative;}
.form-control.daterangepicker-input .InputFromTo .DayPickerInput{width: 50%;}
.form-control.daterangepicker-input .InputFromTo input{border:0; padding:17px 0; height: 57px; line-height: 23px; font-size: 17px; font-family: 'averta_cyregular', sans-serif; width: 100%; padding-right: 30px; background: none;}
.form-control.daterangepicker-input .InputFromTo .dash{position: absolute; left: 50%; margin-left: -20px;}

.otp-input-block{flex-grow: 1;}
.otp-input-block input{flex-grow: 1;border-radius: 0; padding: 17px 0; height: 58px; border: 0; border-bottom: 1px solid #EFF2F7; box-shadow: none!important; color: #0F1A30; font-size: 17px; line-height: 17px; font-family: 'averta_cyregular', sans-serif;}
.otp-input-block input:focus{border-bottom-color:#252ED1; color:#0F1A30;}
.otp-input-block span{display: inline-block; padding: 0 5px;}
.grey-small-text{font-size: 14px; color:#D1D2E0;}

/* Modal
--------------------------------------------------------------------------------------------------------*/
.modal-content{border-radius: 20px; border: 0;}
.modal-content .modal-header{border-radius: 20px 20px 0 0; padding: 20px; border-bottom: 1px solid #EFF2F7;}
.modal-content .modal-header.no-border{border:0;}
.modal-content .modal-header .modal-title{font-size: 18px; line-height: 18px; font-family: 'averta_cysemibold', sans-serif;}
.modal-content .modal-header .close{padding: 0; width: 18px; height: 18px; background:url(../images/close-icon.svg) no-repeat center center; opacity: 1; margin: 0;}
.modal-content .modal-header .close span{display: none;}

.modal-content .modal-body{padding: 20px;}
.modal-content .modal-body p{font-size: 16px; line-height: 21px; color: #98A2A8; word-break: break-word;}
.modal-content .modal-body p:last-child{padding-bottom: 0;}

.modal-content .modal-footer{border-top: 0; padding:0 20px 20px; display: block;}
.modal-content .modal-footer .btns-outer{max-width: 400px; margin: 0 auto; padding: 0 15px;}
.modal-content .modal-footer .btn{margin: 0;}

/* Loader
--------------------------------------------------------------------------------------------------------*/
.page-loader{position: fixed; z-index: 99999; left: 0; top: 0; width: 100%; height: 100%; background: rgba(255,255,255,0.5); display: flex; align-items: center; justify-content: center;}
.page-loader .loader-animation {display: inline-block; position: relative; width: 80px; height: 80px;}
.page-loader .loader-animation div {position: absolute; border: 4px solid #252ED1; opacity: 1; border-radius: 50%; animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;}
.page-loader .loader-animation div:nth-child(2) {animation-delay: -0.5s;}
.page-loader-disable { display: none; }

.data-loader{height: calc(100vh - 350px);  display: flex; align-items: center; justify-content: center;}
.data-loader .loader-animation {display: inline-block; position: relative; width: 80px; height: 80px;}
.data-loader .loader-animation div {position: absolute; border: 4px solid #252ED1; opacity: 1; border-radius: 50%; animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;}
.data-loader .loader-animation div:nth-child(2) {animation-delay: -0.5s;}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}





/* Sidebar
--------------------------------------------------------------------------------------------------------*/
.sidebar{padding: 25px; min-width: 310px; width: 310px;}
.sidebar .sidebar-top-part{padding-bottom: 36px;}
.sidebar .user-img{display: block; margin:0 auto 10px; border-radius: 26px; width: 120px; height: 120px;}
.sidebar .user-dropdown{margin-bottom: 10px;}
.sidebar .user-dropdown .dropdown-toggle{padding: 0; color: #fff!important; font-size: 24px; line-height: 30px; font-family: "averta_cyregular", sans-serif; text-decoration: none;}  
.sidebar .user-dropdown .dropdown-toggle::after{border:0; height: 9px; width: 14px; background: url(../images/dropdown-arrow.svg) center no-repeat; position: relative; top: 5px; margin-left: 10px;}
.sidebar .user-dropdown .dropdown-menu{border:0; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); transform: translate(-50%, 0)!important; left: 50%!important; top: 110%!important;}
.sidebar .user-dropdown .dropdown-item{padding: 12px 12px 12px 40px; font-size: 17px; line-height: 17px; color: #0F1A30!important; background: none!important; position: relative;}
.sidebar .user-dropdown .dropdown-item:hover{color: #FF6363!important;}
.sidebar .user-dropdown .dropdown-item i{position: absolute; left: 12px; top: 11px; width: 20px; height: 20px; overflow: hidden;}
.sidebar .user-dropdown .dropdown-item i img{display: block; width: 20px; height: 40px; transition: all 0.3s; position: relative; top: 0;}
.sidebar .user-dropdown .dropdown-item:hover i img{top: -20px;}
.sidebar .company-name{color: #fff;}

/* MainNavigation
--------------------------------------------------------------------------------------------------------*/
.main-navigation {margin:0 -10px; padding-top: 30px;}
.main-navigation .nav-item{padding: 10px; min-width: 50%; width: 50%;}
.main-navigation .nav-item a{display: block; text-align: center; border: 1px solid #DDDDDD; border-radius: 8px; color: #0F1A30; padding: 30px 10px 0; text-align: center; height: 120px;}
.main-navigation .nav-item a:hover{background: #DDDDDD;}
.main-navigation .nav-item a i{display: block; width: 24px; height: 24px; margin:0 auto 12px; overflow: hidden;}
.main-navigation .nav-item a.active{background: #252ED1; color: #fff; border-color: #252ED1;}
.main-navigation .nav-item a.active i img{position: relative; top: -24px;}

/* Main Content
--------------------------------------------------------------------------------------------------------*/
.main-content {flex-grow: 1; color: #fff; padding: 0 35px 35px 0;}
.page-heading{line-height: 42px; padding: 0; margin:-80px 200px 35px 0; position: relative; z-index: 2;} 
.page-heading span{padding-left: 15px; font-size: 16px; color: #81F190; line-height: 16px;}
.page-heading span.red-text{color:#FF6363;}
.page-heading em{font-size: 14px; line-height: 18px;}


.account-summary .account-summary-detail{background: rgba(255,255,255,0.1); padding: 20px; border-radius: 8px; height: 100%;}
.account-summary .account-summary-detail:hover{background: rgba(255,255,255,0.2);}
.account-summary .account-summary-detail:before{content:""; position: absolute; left: 50%; top: 50%; transition: all 0.2s; width: 0; height: 0; border-radius: 8px; background-color: #fff; z-index: -1;}
.account-summary .account-summary-detail.active{color:#252ED1;}
.account-summary .account-summary-detail.active:before{width: 100%; height: 100%; left: 0; top: 0;}
.account-summary .account-summary-detail p{padding-bottom: 15px;}
.account-summary .account-summary-detail h4{font-family: "averta_cybold", sans-serif; padding: 0;}
.account-summary li {cursor: pointer;}

.tab .tab-heading li a{display: block; padding:15px 20px; cursor: pointer; border-radius: 8px 8px 0 0; font-size: 18px; line-height: 22px;}
.tab .tab-heading li a:hover{background: rgba(255,255,255,0.1);}
.tab .tab-heading li.active a{background: #fff; color: #252ED1; box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.15);}
.tab .tab-content{background: #fff; box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15); border-radius: 8px; color: #0F1A30; padding: 40px;}
.tab .tab-content.without-redius{border-top-left-radius: 0;}

.no-bank-account-block{text-align: center; height: 450px;}
.no-bank-account-block .no-bank-account-img{display: block; margin: 0 auto 30px;}
.no-bank-account-block p{max-width: 290px; margin: 0 auto; color: #98A2A8; padding: 0 0 30px;}
.no-bank-account-block .add-btn{min-width: 220px;}

.bank-checking-summary-listing{margin-bottom: -30px;}
.bank-checking-summary-listing > div{margin-bottom: 30px;}
.bank-checking-summary{border: 1px solid #EFF2F7; padding: 30px; border-radius: 8px; cursor: pointer;}
.bank-checking-summary:hover{box-shadow: 0 0 10px rgba(0,0,0,0.1);}
.bank-checking-summary .bank-checking-summary-header p{padding: 0; color: #181C1F; font-family: "averta_cysemibold";}
.bank-checking-summary .bank-checking-summary-header p.bank-name{flex-grow: 1; position: relative; padding:8px 10px 8px 50px; }
.bank-checking-summary .bank-checking-summary-header p.bank-name .bank-logo{position: absolute; left: 0; top: 0; width: 38px; height: 38px; border: 1px solid #EFEFEF; border-radius: 38px;}
.bank-checking-summary .bank-checking-summary-header .total-amount{text-align: right;}
.bank-checking-summary .bank-checking-summary-header .total-amount span{display: block; font-size: 12px; line-height: 16px; padding-bottom: 8px; font-family: "averta_cyregular"; white-space: nowrap;}
.bank-checking-summary .bank-checking-summary-detail li{display: flex; justify-content: space-between;}
.bank-checking-summary .bank-checking-summary-detail li + li {padding-top: 26px;}
.bank-checking-summary .bank-checking-summary-detail li span{display: block; padding: 4px 0; font-size: 14px; line-height: 18px;}
.bank-checking-summary .bank-checking-summary-detail li span.grey-text{color:#98A2A8;}

.back-btn-outer{margin:-72px 200px 43px 0; position: relative; z-index: 2;}
.back-btn-outer .back-btn{position: relative; display: inline-block; padding-left: 20px; font-size: 20px; line-height: 30px; color: #fff;cursor: pointer}
.back-btn-outer .back-btn i{position: absolute; left: 0; top: 7px; width: 10px; height: 16px; overflow: hidden; background: url(../images/back-btn-arrow.svg) 0 0 no-repeat;}
.page-content{background: #fff; padding: 30px; box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15); color: #0F1A30; border-radius: 8px;     min-height: calc(100vh - 160px);}
.search-input{background: #F6F8FC url(../images/search-icon.svg) 15px center no-repeat; border:1px solid transparent; width: 255px; border-radius: 8px; padding: 15px 15px 15px 45px; color:#98A2A8; font-size: 16px; line-height: 20px; height: 52px;}    
.search-input:focus{border-color: #252ED1;}

.add-bank-listing > .col-xl-6{margin-bottom: 30px;}
.add-bank-detail{border: 1px solid #EFF2F7; border-radius: 14px; padding: 15px 20px;}
.add-bank-detail .add-bank-logo{display: block; width: 36px; height: 36px;}
.add-bank-detail .status{display: block; color: #98A2A8; padding-left: 12px; position: relative;}
.add-bank-detail .status:before{content:""; width: 6px; height: 6px; border-radius: 6px; background: #FF6363; position: absolute; left: 0; top: 8px;}
.add-bank-detail  .status.connected{color: #5AC868;}
.add-bank-detail  .status.connected:before{background: #5AC868;}

.modal .add-bank-modal-logo {display: block; width: 128px; height: 128px; margin:-70px auto 10px;}
.disconnect-bank-detail{border: 1px solid #EFF2F7; border-radius: 14px; text-transform: uppercase; font-size: 12px;}
.disconnect-bank-detail li{padding: 20px;}
.disconnect-bank-detail li + li{border-top: 1px solid #EFF2F7;}
.disconnect-bank-detail li p{font-size: 12px!important; line-height: 16px!important; font-family: "averta_cysemibold";}
.disconnect-bank-detail li p.dark-text{color: #0F1A30!important;}

.notifications-listing .notification-detail:not(:last-child){padding-bottom: 20px; margin-bottom: 20px; border-bottom:1px solid #EFF2F7;}
.notifications-listing .notification-detail p{color: #98A2A8; font-size: 14px; line-height: 18px;}

.change-password-form{border: 1px solid #DDDDDD; border-radius: 8px; padding: 30px;}
.language-dropdown{max-width: 260px;}
.language-dropdown .dropdown-toggle{padding: 15px 0; color:#0F1A30; font-size: 16px; line-height: 20px; font-family: "averta_cyregular", sans-serif; text-decoration: none; border-bottom: solid 1px #EFF2F7!important; border-radius: 0; width: 100%; text-align: left;}
.language-dropdown.small .dropdown-toggle{padding: 2px 0; border-bottom: 0!important; font-size: 14px;}
.language-dropdown .dropdown-toggle::after{border:0; height: 9px; width: 14px; background: url(../images/dropdown-arrow-dark.svg) center no-repeat; position: relative; top: 7px; margin-left: 10px; float: right;}
.language-dropdown .dropdown-menu{border:0; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);}
.language-dropdown .dropdown-item{padding: 7px 15px; font-size: 16px; line-height: 20px; color: #0F1A30!important; background: none!important; position: relative;}
.language-dropdown .dropdown-item:hover{color: #FF6363!important;}
.language-dropdown .dropdown-item i{position: absolute; left: 12px; top: 11px; width: 20px; height: 20px; overflow: hidden;}
.language-dropdown .dropdown-item i img{display: block; width: 20px; height: 40px; transition: all 0.3s; position: relative; top: 0;}
.language-dropdown .dropdown-item:hover i img{top: -20px;}

.expenses-summary .expenses-summary-detail{background:#252ED1; border:solid 1px rgba(255,255,255,0.2); padding: 20px 20px 20px 90px; border-radius: 8px; height: 100%; position: relative; color: #fff;}
.expenses-summary .expenses-summary-detail:hover{background: #242dc3;}
.expenses-summary .expenses-summary-detail i{position: absolute; left: 20px; top: 50%; margin-top: -25px; padding: 15px; background: rgba(255,255,255,0.1); border-radius: 6px;}
.expenses-summary .expenses-summary-detail i img{display: block; width: 20px; height: 20px;}
.expenses-summary .expenses-summary-detail p{padding-bottom: 5px;}
.expenses-summary .expenses-summary-detail h4{font-family: "averta_cybold", sans-serif; padding: 0;}

.expenses-page.page-content{min-height: calc(100vh - 300px);}
.expenses-filter li + li{padding-left: 40px;}
.expenses-filter li a{font-size: 18px; line-height: 22px; color:#98A2A8 !important; display: block; position: relative;}
.expenses-filter li.active a{color:#0F1A30 !important;}
.expenses-filter li.active a:after{position: absolute; content: ""; bottom:-5px; left: 0; width: 15px; border-top: solid 2px #0F1A30;}

.expenses-listing .expenses-listing-detail{border-bottom: 1px solid #EFF2F7; padding: 15px 115px 15px 25px; height: 100%; position: relative;}
.expenses-listing .expenses-listing-detail .expenses-listing-right-part{position: absolute; right: 25px; top: 15px;}
.expenses-listing .expenses-listing-detail p{font-size: 14px; line-height: 18px;}
.expenses-listing .expenses-listing-detail p.grey-text{color: #98A2A8;}

.transactions-history-listing > .col-xl-6{margin-bottom: 30px;}
.transactions-history-detail{border: 1px solid #EFF2F7; border-radius: 14px; padding: 15px 20px; height: 100%;}
.transactions-history-detail .transactions-history-logo-outer{display: block; width: 48px; height: 48px; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); border-radius: 48px; padding: 12px;}
.transactions-history-detail .transactions-history-logo{display: block; width: 24px; height: 24px;}
.transactions-history-detail span{display: block; color: #98A2A8; font-size: 14px; line-height: 18px;}
.transactions-history-detail p{font-size: 14px; line-height: 18px;}
.transactions-history-detail em{display: block; font-size: 12px; line-height: 16px;}


.profile-img-block{position: relative; width: 120px; margin: 0 auto;}
.profile-img-block .user-img{display: block; width: 120px; height: 120px; border-radius: 26px;}
.profile-img-block .profile-img-edit{position: absolute; right: -12px; bottom: -12px; width: auto; height: auto;}
.profile-img-block .profile-img-edit .custom-file-input{position: absolute; width: 0; height: 0;}
.profile-img-block .profile-img-edit .custom-file-label{position: static; display: block; width: 42px; height: 42px; border-radius: 42px; background: #F8F8F8 url(../images/camera-icon.svg) no-repeat center center; border-color:#fff; cursor: pointer; box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);}
.profile-img-block .profile-img-edit .custom-file-label::after{display: none;}

.profile-form .form-label{font-size: 14px; line-height: 14px; display: block; color: #98A2A8; margin-bottom: -15px;position: relative;z-index: 2;}
.profile-form-outer:not(.edit-mode) .edit-mode-label{display: none;}
.profile-form-outer.edit-mode .normal-label{display: none;}
.profile-form-outer:not(.edit-mode) .profile-img-block-outer{display: none;}
.profile-form-outer:not(.edit-mode) .form-control{border-bottom: transparent!important; pointer-events: none;}
.profile-form-outer:not(.edit-mode) .custom-select{border-bottom: transparent!important; pointer-events: none; background: none!important; padding-right: 0;}


.transaction-bank .transaction-bank-icon{border: 1px solid #98A2A8; width: 71px; height: 71px; display: block; border-radius: 71px;}
.transaction-bank span{display: block; font-size: 14px; line-height: 18px; color: #98A2A8; padding-bottom: 6px;}
.transaction-listing .transaction-date{font-size: 14px; line-height: 18px; color: #98A2A8; padding-bottom: 6px;}

.transaction-listing .transaction-detail{border: 1px solid #EFF2F7; border-radius: 14px; padding:0; max-width: 500px; margin-bottom: 20px;}
.transaction-listing .transaction-detail .card-header{background: none; border:0; padding: 15px;}
.transaction-listing .transaction-detail .transaction-icon{display: block; padding: 11px; border: 1px solid #EFEFEF; border-radius: 48px;}
.transaction-listing .transaction-detail .transaction-icon img{display: block; width: 24px; height: 24px;}
.transaction-listing .transaction-detail .grey-small-text{font-size: 14px; line-height: 18px; display: block; color: #98A2A8;}
.transaction-listing .transaction-detail .toggle-btn{border-radius: 40px;  padding: 11px 16px; transition: all 0.3s;}
.rotate{transform: rotate(90deg);}
.transaction-listing .transaction-detail .card-body{padding: 15px;}

.add-bank-listing .add-bank-detail .card-header{background: none; border:0; padding: 0;}
.add-bank-listing .add-bank-detail .card-body.bank-checking-summary{padding:20px 0 0; border-radius: 0; border: 0; box-shadow: none!important; cursor: default;}
.add-bank-listing .add-bank-detail .card-body.bank-checking-summary .bank-checking-summary-detail li{cursor: pointer;}
.add-bank-listing .add-bank-detail .card-body.bank-checking-summary .bank-checking-summary-detail li + li{padding-top: 0; margin-top: 26px;}


.no-data-found{min-height: 190px; height: calc(100vh - 450px);  display: flex; align-items: center; justify-content: center; text-align: center;}
.no-data-found .no-data-found-img{display: block; margin: 0 auto 20px; width: 150px;}
.cursorPointer {cursor: pointer;}
.displayBlock {display: block;}
.DatePickerInput > div {position: absolute; background-color: white; z-index: 9; box-shadow: 0 0 8px rgba(0,0,0,0.3);}

.center-chart .recharts-wrapper{margin: auto;}
.recharts-wrapper .recharts-surface{overflow: visible;}

/* Export Dropdown */
.export-dropdown{display: inline-block;}
.export-dropdown .dropdown-toggle .export-icon{display: inline-block; width: 20px; margin-bottom: -2px;}
.export-dropdown .dropdown-toggle::after{border:0; height: 9px; width: 14px; background: url(../images/dropdown-arrow-dark.svg) center no-repeat; position: relative; top: 5px; margin-left: 10px;}
.export-dropdown .dropdown-menu{border:0; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);}
.export-dropdown .dropdown-item{padding: 12px 12px 12px 40px; font-size: 17px; line-height: 17px; color: #0F1A30!important; background: none!important; position: relative;}
.export-dropdown .dropdown-item:hover{color: #FF6363!important;}
.export-dropdown .dropdown-item i{position: absolute; left: 12px; top: 11px; width: 20px; height: 20px; overflow: hidden;}
.export-dropdown .dropdown-item i img{display: block; width: 20px; height: 40px; transition: all 0.3s; position: relative; top: 0;}
.export-dropdown .dropdown-item:hover i img{top: -20px;}

/* Footer
--------------------------------------------------------------------------------------------------------*/
.footer { background: #333; padding: 15px; text-align: center;}
.copyright { padding: 0; color: #fff; font-size: 0.8em; line-height: 1.2em; }



/* New CSS
--------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------*/
/* success-block */
.success-block{text-align: center;}
.success-block img{ width: 80px; height: 80px; display: block; margin: 0 auto 14px; }
.success-block h6{ font-size: 30px; line-height: 34px; letter-spacing: -0.3px; font-weight: 600; color: #40C35B; max-width: 383px; padding: 0; margin: 0 auto 14px; }
.success-block p{ font-size: 17px; line-height: 21px; font-weight: 400; color: #98A2A8; max-width: 383px; margin: 0 auto 14px; padding: 0; }


/* filled-select */
.filled-select .custom-dropdown__control{ background: #252ED1; padding: 19px 20px; border-radius: 14px; cursor: pointer; border: 0!important; box-shadow: none!important; min-height: auto; justify-content: center; }
.filled-select .custom-dropdown__control .custom-dropdown__value-container{ flex: none; }
.filled-select .custom-dropdown__control .custom-dropdown__value-container .custom-dropdown__placeholder{ position: static; transform: none; font-size: 18px; line-height: 22px; color: #FFF; font-weight: 600; letter-spacing: 0; }
.filled-select .custom-dropdown__control .custom-dropdown__value-container .custom-dropdown__input{ opacity: 0!important; }
.filled-select .custom-dropdown__control .custom-dropdown__indicators .custom-dropdown__indicator-separator{ display: none; }
.filled-select .custom-dropdown__control .custom-dropdown__indicators .custom-dropdown__dropdown-indicator path{ display: none; }
.filled-select .custom-dropdown__control .custom-dropdown__indicators .custom-dropdown__dropdown-indicator{ margin-left: -7px; padding: 0; background: url('../images/dropdown-arrow.svg') no-repeat top 6px center; background-size: 12px 12px; }


/* bank transfer selection */
.bank-transfer-selection{ display: flex; justify-content: space-between; align-items: center; margin-bottom: 30px; }
.bank-transfer-selection .bank-transfer-single-selection{ flex-grow: 1; width: 50%;}
.selection-control-label{ color: #0F1A30; font-size: 24px; line-height: 30px; font-weight: 400; display: block; padding-bottom: 14px; }
.bank-transfer-selection .separator{ width: 28px; height: 28px; min-width: 28px; flex-basis: 28px; margin: 44px 7px 0; }


.bank-transfer-single-selection .bank-checking-summary-detail{display: flex; justify-content: space-between; flex-wrap: wrap; border-radius: 14px; border: 1px solid #DDDDDD; padding: 9px 9px; }
.bank-transfer-single-selection .bank-checking-summary-detail div span{display: block; padding: 4px; font-size: 14px; line-height: 18px;}
.bank-transfer-single-selection .bank-checking-summary-detail div span.grey-text{color:#98A2A8;}
.bank-transfer-single-selection .bank-checking-summary-detail .text-right{ margin-left: auto; }

.transfer-confirm-form .label-color.form-label{color: #98A2A8; font-size: 14px;}

.saved-card{ position: relative; display: flex; align-items: center; justify-content: space-between; border: 1px solid #EFF2F7; border-radius: 14px; padding: 13px 60px 13px 20px; transition: all 0.2s ease; margin-bottom: 20px; width: 100%; background-color: transparent;}
.saved-card::after{ content: ""; display: block; position: absolute; top: 50%; transform: translate(0, -50%); right: 16px; background: url('../images/next-arrow.svg') no-repeat center; background-size: contain; width: 14px; height: 14px; }
.saved-card div{ display: flex; align-items: center;  }
.successText{ color: #5AC868!important; }
.errorText{ color: #EB001B!important; }
.saved-card div i{ display: inline-block; vertical-align: middle; width: 44px; height: 44px; margin-right: 20px; }
.saved-card div p{ display: block; padding: 0; color: #0F1A30; font-size: 16px; line-height: 20px; }
.saved-card div span{ display: block; font-size: 14px; line-height: 18px; color: #98A2A8; margin-top: 4px; text-align: left;}
.saved-card:hover{ border-color: #252ED1; }

.preview-card{ display: block; background: #F2F3F5; border-radius: 28px; padding: 22px 28px; }
.preview-card .card-icon{ width: 44px; height: 44px; margin-bottom: 6px; }
.preview-card .card-number, .preview-card .card-name, .preview-card .card-expiry{ font-size: 17px; line-height: 34px; color: #0F1A30; padding: 0 0 5px;}

.filled-alert-box{ position: relative; padding: 10px 10px 10px 40px; border-radius: 7px; }
.filled-alert-box .alert-icon{ position: absolute; top: 12px; left: 10px; width: 20px; height: 20px; margin-right: 20px; }
.filled-alert-box .alert-message{ display: block; font-size: 16px; line-height: 22px; }

.filled-alert-box.danger{ background: rgba(235, 0, 27, 0.07); }
.filled-alert-box.danger .alert-message{ color: #EB001B; }

.subscription-card{ position: relative; display: block; border-radius: 28px; cursor: pointer; background: #F2F3F5; min-height: calc(100% - 30px); margin-bottom: 30px; transition: all 0.4s ease;  }
.subscription-card:hover, .subscription-card:active, .subscription-card.active{ background: #FFF; box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3); }
.subscription-card .subscription-card-header{ padding: 22px 20px 30px; text-align: center; border-bottom: 1px solid #CCCCCC; }
.subscription-card .subscription-card-header .subscription-type{ font-size: 20px; line-height: 24px; color: #0F1A30; display: block; padding-bottom: 18px; }
.subscription-card .subscription-card-header .subscription-amount{ font-size: 36px; line-height: 44px; color: #0F1A30; padding-bottom: 0; font-weight: 700; }
.subscription-card.active .subscription-card-header .subscription-amount{ color: #5AC868; }
.subscription-card .subscription-card-body{ padding: 20px 40px; }
.subscription-card .subscription-card-body .check-listing{ margin-bottom: 30px; }
.subscription-card .subscription-card-body button{ margin: 0 auto 12px; display: block; min-width: 220px; }  
.subscription-card .subscription-card-body .card-note{ font-size: 14px; line-height: 16px; color: rgba(15, 26, 48, 0.6); display: block; text-align: center; }

.check-listing li{ font-size: 16px; line-height: 20px; color: #0F1A30; position: relative; padding-left: 30px; }
.check-listing li + li{ margin-top: 20px; }
.check-listing li::before{ content: ""; position: absolute; top: 2px; left: 0; width: 16px; height: 16px; background: url('../images/checked-icon-blue.svg') no-repeat center; background-size: 16px 16px; }

.btn-success-outlined{ border: 1px solid #5AC868!important; color: #5AC868; background: transparent; }
.btn-success-outlined:hover, .btn-success-outlined:active, .btn-success-outlined:focus{ border: 1px solid #5AC868!important; color: #5AC868!important; background: #d2ffdc!important; }
.btn-primary-outlined{ border: 1px solid #252ED1!important; color: #252ED1; background: transparent; }
.btn-primary-outlined:hover, .btn-primary-outlined:focus, .btn-primary-outlined:active{ color: #252ED1!important; background: rgb(195, 198, 255, 0.56)!important; }

.bold{ font-weight: 700!important; }
.link-blue{ color: #007aff; text-decoration: underline; }
.link-blue:hover, .link-blue:focus, .link-blue:active{ color: #3c45ef; }


/* Responsive Media Queries
--------------------------------------------------------------------------------------------------------*/
 @media (min-width: 1440px){ 
  .col-xxl-6 {flex: 0 0 50%;max-width: 50%;}
}

@media (max-width: 1440px){ 
  .subscription-card .subscription-card-header{ padding: 15px; }
  .subscription-card .subscription-card-header .subscription-type{ font-size: 18px; line-height: 22px; padding-bottom: 10px; }
  .subscription-card .subscription-card-header .subscription-amount{ font-size: 24px; line-height: 28px; }
  .subscription-card .subscription-card-body{ padding: 20px; }
  .subscription-card .subscription-card-body .check-listing{ margin-bottom: 20px; }
  .subscription-card .subscription-card-body button{ min-width: 180px; padding: 12px 20px; font-size: 16px; line-height: 20px; }
}

@media (max-width:1199px) {
  .add-bank-listing > .col-xl-6, .transactions-history-listing > .col-xl-6{margin-bottom: 20px;}

   
  .filled-select .custom-dropdown__control{ padding: 15px 20px; }
  .selection-control-label{ font-size: 18px; line-height: 22px; padding-bottom: 8px; }
  .bank-transfer-selection .separator{ margin: 20px 8px 10px; }
  .bank-transfer-selection .separator img{ transform: rotate(90deg); }
  .bank-transfer-single-selection .bank-checking-summary-detail{ padding: 7px 15px; }
  .bank-transfer-single-selection .bank-checking-summary-detail div span{ padding: 3px 0; }

  .bank-transfer-selection{ flex-direction: column; }
  .bank-transfer-selection .bank-transfer-single-selection{ min-width: 100%; }
}

@media (min-width:992px) {
  .main-navigation .nav-item.logout-link{width: 100%; min-width: 100%;}
  .main-navigation .nav-item.logout-link a{display: flex; align-items: center; justify-content: center; padding: 15px; height: auto;}
  .main-navigation .nav-item.logout-link a i{margin: 0 10px 0 0;}
}

@media (max-width:991px) {
  .page-header{padding: 25px;}
  .page-header .logo-link-outer{width: auto; padding: 0;}
  .sidebar{position: fixed; top: 0; left: 0; height: 100%; background: #fff; z-index: 100; box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15); width: 280px; min-width: 280px; padding: 0; overflow: auto; margin-left: -290px; transition: all 0.3s;}
  .menu-open .sidebar{margin-left: 0;}
  .sidebar-overlay{position: fixed; top: 0; left: 0; height: 100%; width: 100%; background: rgba(0, 0, 0, 0.7); z-index: 99; transition: all 0.3s; opacity: 0; visibility: hidden;}
  .menu-open .sidebar-overlay{opacity: 1; visibility: visible;}
  .sidebar .sidebar-top-part{padding: 25px; background: #252ED1;}
  .nav-icon{margin-left: 10px; padding: 0 7px; width: 40px; height: 40px;}
  .nav-icon:hover{background:#3c45ef!important;}
  .nav-icon span{height: 2px; display: block; background: #fff; margin: 7px 0;}

  .main-navigation{margin: 0; padding: 0; display: block; border-top: solid 1px #fff;}
  .main-navigation .nav-item{padding: 0; border-bottom: 1px solid #DDDDDD; width: auto; }
  .main-navigation .nav-item a{border-radius: 0; border:0; position: relative; text-align: left; padding: 20px 20px 20px 50px; height:auto;}
  .main-navigation .nav-item a i{position: absolute; left: 10px; top: 17px; margin: 0;}
  .main-content{padding: 0 25px 25px;}  
  .page-heading{margin: 0 0 25px; position: static;}
  .back-btn-outer{margin: 0 0 25px;}
  .tab .tab-content{padding: 25px;}

  .expenses-page.page-content{min-height:calc(100vh - 330px);}
  
  .check-listing li + li{ margin-top: 15px; }
  .preview-card .card-icon{ width: 40px; height: 40px; }
  .preview-card .card-number, .preview-card .card-name, .preview-card .card-expiry{ font-size: 14px; line-height: 24px; }
  .btn-lg{ padding: 10px 18px; font-size: 15px; line-height: 19px; }
  
  .custom-check .form-check .form-check-label{ font-size: 15px; line-height: 24px; }
  .custom-check .form-check .form-check-label::before, .custom-check .form-check .form-check-label::after{ width: 24px; height: 24px; }
  
}

@media (max-width:767px) {
  .page-header{padding: 25px 15px; position: fixed; left: 0; top: 0; width: 100%; background: #252ED1; z-index: 3; box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15); min-width: 320px;}
  .page-header:before{display: none;}
  .page-heading{margin-bottom: 15px;}
  .page-heading span{color: #52a95e;}
  .page-heading span.red-text{color: #d00000;}
  .main-content{padding: 110px 15px 15px; color: #181C1F;}  
  .tab .tab-content{padding: 15px;}
  .bank-checking-summary-listing{margin-bottom: -15px;}
  .bank-checking-summary-listing > div{margin-bottom: 15px;}
  .account-summary .account-summary-detail{background:#252ed1!important; color:#fff;}
  .account-summary .account-summary-detail.active{background:#fff!important; color:#252ed1;}
  
  .back-btn-outer{margin: 0 0 15px;}
  .back-btn-outer .back-btn{color:#181C1F;}
  .back-btn-outer .back-btn i{background-position: left bottom;}
  .page-content{padding: 15px; min-height: calc(100vh - 170px);}
  .change-password-form{padding: 15px;}


  .success-block img{ width: 60px; height: 60px; margin: 0 auto 8px; }
  .success-block h6{ font-size: 26px; line-height: 30px; margin: 0 auto 12px; }
  .success-block p{ font-size: 16px; line-height: 20px; }

  .preview-card{ padding: 15px 18px; border-radius: 14px; }

  h4{  font-size: 22px; line-height: 28px; }
 }

@media (min-width:576px) {
    .modal-dialog:not(.modal-xl){max-width: 375px;}
}

@media (max-width:575px) {
  .account-summary li + li{margin-top:15px;}
  .search-input{width: 100%;}
  .add-bank-listing > .col-xl-6, .transactions-history-listing > .col-xl-6{margin-bottom: 15px;}
  .language-dropdown{max-width: inherit;}

  .saved-card{ flex-direction: row; }
  .saved-card div { display: block; }
}

.DayPicker-NavBar span {top:31px;}

input[type='checkbox'] {-webkit-appearance: none; -moz-appearance: none; appearance: none; display: inline-block; width: 25px; height: 25px; padding: 6px;  border: 1.5px solid #bbbbbb; border-radius: 6px; background-color: #e7e6e7; margin-right: 10px; }
input[type='checkbox']:checked {background-color: #252ED1}
input[type='checkbox']:checked::after {content: '✔';color: #ffffff; transition: all .2s; position: absolute;bottom: 31px;}
input[type='checkbox']:focus {outline: none !important;}
.bankCheckbox{margin: 10px; display: inline-flex;}
.transfer-count{float: right;margin-top: 5px;color: black;font-family: 'averta_cyregular', sans-serif;}

.square-check .form-check .form-check-label::before,.square-check .form-check .form-check-label::after {border-radius: 6px !important;}

.toggle-container{display:flex; justify-content:center; align-items:center; text-align:center;}
.toggle-button{width:50%; cursor:pointer;}
.toggle-button:hover{ background-color: #98A2A8;}
.toggle-content{font-size:'1rem';padding:1rem 0rem;}

.news-container{height: 100%;}
.news-container:hover{box-shadow: 0 0 10px rgba(0,0,0,0.2);}
.news-container .news-link{font-size:12px; color:#8b969c;}
.news-container .news-post-time{font-size: 12px; line-height: 16px; padding:30px 0 0; color:#8b969c;}