.divCard{
    border:1px solid #ccc!important;
    padding: 30px;
    height: 45%;
    width: 35%;
    border-radius: 16px;
    margin-left: 33%;
    margin-top: 5%;
}

.headerTitle{
    padding-top: 30px;
    text-align: center;
}

.min-w-130{
    min-width: 130px;;
}

.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
}

.InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
}

.InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}

.InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}

.InputFromTo .DayPickerInput-Overlay {
    width: 550px;
}

.InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -198px;
}

.InputFromTo .DayPickerInput input {
    text-align: center;
}

.dvMax {
    max-width: none !important;
}

.fullScreen{
    overflow:hidden;
    overflow-x:hidden;
    overflow-y:hidden;
    height:100%;
    width:100%;
    position:absolute;
    top:0px;left:0px;
    right:0px;
    bottom:0px
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.font-15 {
    font-size: 15px !important;
}

.transaction-detail.from-history { max-width: 800px; }